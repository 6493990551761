import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { LogisticApi } from "@/api";
export default {
  props: ["editaddress", "countrychooseId"],
  data: function data() {
    return {
      countryList: [],
      stateList: [],
      address: [],
      cityList: [],
      createForm: this.$form.createForm(this, {
        name: "add_logistic"
      })
    };
  },
  computed: {
    countryid: function countryid() {
      return +this.countrychooseId || "";
    },
    suAdress: function suAdress() {
      var _this = this;
      var newArry = [];
      this.address.forEach(function (item) {
        // console.log(item.indexOf("全部") != -1);
        var country = "";
        if (item.indexOf("全部") != -1) {
          country = item.split("-")[0];
          // console.log(country);
          newArry.push(item);
        }
      });
      // console.log("newArry---------------", newArry);
      var newAddres = [];
      if (newArry && newArry.length > 0) {
        newArry.map(function (vs) {
          _this.address.map(function (v, i) {
            if (vs.split("-")[0] == v.split("-")[0] && v.split("-")[1] != "全部") {
              // console.log(v.split("-")[0], vs.split("-")[0]);
              // newArry.push(v);
              // console.log(i);
              _this.address.splice(i, 1);
              // newArry.push(v)
              // newAddres = new Set([...this.address]);
            } else {
              newAddres = _toConsumableArray(_this.address);
            }
          });
        });
      } else {
        newAddres = _toConsumableArray(this.address);
      }
      // console.log("newArry---------------", newAddres);
      return newAddres;
    }
  },
  mounted: function mounted() {
    this.getCityList();
    this.createForm.setFieldsValue({
      expresscompany: this.countryid
    });
    this.getstateList({
      countryid: this.countryid,
      id: this.countryid,
      level: 0
    });
    if (this.editaddress) {
      this.address = this.editaddress;
      // this.createForm.setFieldsValue({
      //     state: this.address,
      // });
    }
  },

  methods: {
    getCityList: function getCityList() {
      var _this2 = this;
      LogisticApi.getNewCountry({
        scene: 1
      }).then(function (res) {
        _this2.countryList = res;
      });
    },
    // 选择省
    getstateList: function getstateList(_ref) {
      var _this3 = this;
      var id = _ref.id,
        countryid = _ref.countryid,
        level = _ref.level;
      LogisticApi.getNewAddress({
        id: id,
        countryid: countryid,
        level: level
      }).then(function (res) {
        level == 0 ? _this3.stateList = res : _this3.cityList = [{
          id: 0,
          cityname: "全部"
        }].concat(_toConsumableArray(res));
      });
    },
    // 选择市
    handleCityChange: function handleCityChange(v, e) {
      console.log(v, e, this.createForm.getFieldValue("state"));
      var str = "".concat(this.createForm.getFieldValue("state"), "-").concat(v);
      this.address.push(str);
      this.address = _toConsumableArray(new Set([].concat(_toConsumableArray(this.address), _toConsumableArray(this.editaddress))));
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    stateChange: function stateChange(res) {
      // console.log(this.createForm.getFieldValue("state"));
      console.log(res);
      this.address.push(res);
      this.address = _toConsumableArray(new Set([].concat(_toConsumableArray(this.address), _toConsumableArray(this.editaddress))));
    },
    //
    // 选择州/省
    handleStateChange: function handleStateChange(v, e) {
      this.state = e;
      this.cityList = [];
      // this.getcityList();
      this.getstateList({
        countryid: this.countryid,
        id: this.state,
        level: 1
      });
      this.createForm.setFieldsValue({
        city: ""
      });
    },
    handleAdd: function handleAdd(e) {
      if (e) {
        this.$emit("handleCancle");
      } else {
        this.$emit("handleAddSubmit", this.address);
      }
    },
    deleteItem: function deleteItem(item) {
      var _this4 = this;
      this.address.map(function (v, i) {
        if (v == item) {
          _this4.address.splice(i, 1);
        }
      });
    }
  }
};