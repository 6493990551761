import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { LogisticApi } from "@/api";
import Addressadd from "./addressadd";
import Sellerconfig from "./comonent/sellerConfig";
import Buyerconfig from "./comonent/buyerConfig";
export default {
  name: "addgoods",
  components: {
    Addressadd: Addressadd,
    Sellerconfig: Sellerconfig,
    Buyerconfig: Buyerconfig
  },
  props: {},
  data: function data() {
    return {
      createForm: this.$form.createForm(this, {
        name: "add_logistic"
      }),
      expresscompanyList: [],
      sellerCity: [],
      buyerCity: [],
      rules: [],
      buyrules: [],
      buyerWareList: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState("base", ["warehouseList"])), {}, {
    editId: function editId() {
      return this.$route.query.templateId;
    },
    seetype: function seetype() {
      return this.$route.query.type ? true : false;
    }
  }),
  watch: {
    rules: {
      handler: function handler(newName, oldName) {
        var _this = this;
        // console.log(newName, oldName);
        this.buyerWareList = [];
        newName.map(function (v) {
          v.warehouseId && _this.buyerWareList.push(v.warehouseId);
          v.chargingList.map(function (vs) {
            vs.receiving && _this.buyerWareList.push(vs.receiving);
          });
        });
        this.buyerWareList = _toConsumableArray(new Set(this.buyerWareList));
      },
      immediate: true,
      deep: true
    }
  },
  mounted: function mounted() {
    this.getCountry();
    this.getWarehouseList();
    this.getcompanyList();
    if (this.editId) {
      this.getDetail();
    }
  },
  methods: _objectSpread(_objectSpread({}, mapActions("base", ["getWarehouseList"])), {}, {
    // 获取详情
    getDetail: function getDetail() {
      var _this2 = this;
      LogisticApi.findone({
        templateId: this.editId
      }).then(function (res) {
        // console.log(res);
        var templateName = res.templateName,
          rules = res.rules,
          routes = res.routes;
        _this2.rules = rules || [];
        _this2.buyrules = routes || [];
        var countryId = rules.map(function (item) {
          return item.countryId;
        });
        var sellcountry = routes.map(function (item) {
          return item.router;
        });
        // console.log(sellcountry);
        _this2.createForm.setFieldsValue({
          templateName: templateName,
          countryId: countryId,
          sellcountry: sellcountry
        });
        if (countryId.length) {
          var Id = "".concat(_this2.sellerCity[countryId[0] - 1].countryid);
          var countryName = "".concat(_this2.sellerCity[countryId[0] - 1].countryname);
          if (countryId.length == 1) {
            _this2.buyerCity = [{
              id: 1,
              toCountryId: Id,
              toCountryName: countryName,
              fromCountryId: Id,
              fromCountryName: countryName,
              name: "".concat(_this2.sellerCity[countryId[0] - 1].countryname, "-").concat(_this2.sellerCity[countryId[0] - 1].countryname)
            }];
          } else if (countryId.length == 2) {
            _this2.buyerCity = [{
              id: 1,
              toCountryId: Id,
              toCountryName: countryName,
              fromCountryId: Id,
              fromCountryName: countryName,
              name: "".concat(_this2.sellerCity[countryId[0] - 1].countryname, "-").concat(_this2.sellerCity[countryId[0] - 1].countryname)
            }, {
              id: 2,
              toCountryId: "".concat(_this2.sellerCity[countryId[1] - 1].countryid),
              toCountryName: "".concat(_this2.sellerCity[countryId[1] - 1].countryname),
              fromCountryId: "".concat(_this2.sellerCity[countryId[1] - 1].countryid),
              fromCountryName: "".concat(_this2.sellerCity[countryId[1] - 1].countryname),
              name: "".concat(_this2.sellerCity[countryId[1] - 1].countryname, "-").concat(_this2.sellerCity[countryId[1] - 1].countryname)
            }, {
              id: 3,
              toCountryId: 2,
              toCountryName: "中国",
              fromCountryId: 1,
              fromCountryName: "United States",
              name: "\u4E2D\u56FD-United States"
            }];
          } else {
            _this2.buyerCity = [];
          }
        }
        _this2.rules.map(function (vs) {
          vs.warehouseName = vs.defaultWarehouse;
          vs.warehouseId = vs.defaultWarehouseId;
          vs.expressCompanyCode = vs.expressCompanyCode;
          vs.chargingList.map(function (u) {
            u.receiving = +u.receiving;
            u.type = 1;
            u.isWarehouse = true;
          });
        });
        _this2.buyrules.map(function (vs) {
          vs.expressCompanyCode = vs.expressCompanyCode;
          // console.log(vs.router.split("-"));
          vs.toCountryName = vs.router.split("-")[0];
          vs.fromCountryName = vs.router.split("-")[1];
          vs.chargingList && vs.chargingList.map(function (u) {
            // u.receiving = u.receiving;
            u.shipping = +u.shipping;
            u.type = 2;
            u.isWarehouse = true;
          });
        });
      });
    },
    getcompanyList: function getcompanyList() {
      var _this3 = this;
      LogisticApi.allexpress().then(function (res) {
        _this3.expresscompanyList = res;
      });
    },
    //卖家选择
    handleChange: function handleChange(e) {
      var _this4 = this;
      if (e.length > this.rules.length) {
        e.map(function (v) {
          _this4.sellerCity.map(function (vs) {
            if (v == vs.countryid) {
              _this4.rules.push({
                countryId: vs.countryid,
                countryName: vs.countryname,
                warehouseId: "",
                defaultPrice: "",
                expressCompanyCode: "",
                chargingList: [{
                  // chargingId: "",
                  shipping: "",
                  receiving: "",
                  isWarehouse: true,
                  price: "",
                  type: 1
                }]
              });
              var obj = {};
              _this4.rules = _this4.rules.reduce(function (cur, next) {
                obj[next.countryId] ? "" : obj[next.countryId] = true && cur.push(next);
                return cur;
              }, []);
            }
          });
        });
      }
      this.rules = this.rules.filter(function (item) {
        return e.includes(item.countryId);
      });
      // console.log(e, this.rules, 1111);
      if (e.length) {
        var Id = "".concat(this.sellerCity[e[0] - 1].countryid);
        var countryName = "".concat(this.sellerCity[e[0] - 1].countryname);
        if (e.length == 1) {
          console.log(e);
          var usCity = {
            id: 1,
            toCountryId: Id,
            toCountryName: countryName,
            fromCountryId: Id,
            fromCountryName: countryName,
            name: "".concat(this.sellerCity[e[0] - 1].countryname, "-").concat(this.sellerCity[e[0] - 1].countryname)
          };
          this.buyerCity = e[0] == 2 ? [_objectSpread({}, usCity), {
            id: 3,
            toCountryId: 2,
            toCountryName: "中国",
            fromCountryId: 1,
            fromCountryName: "United States",
            name: "\u4E2D\u56FD-United States"
          }] : [_objectSpread({}, usCity)];
        } else if (e.length == 2) {
          this.buyerCity = [{
            id: 1,
            toCountryId: Id,
            toCountryName: countryName,
            fromCountryId: Id,
            fromCountryName: countryName,
            name: "".concat(this.sellerCity[e[0] - 1].countryname, "-").concat(this.sellerCity[e[0] - 1].countryname)
          }, {
            id: 2,
            toCountryId: "".concat(this.sellerCity[e[1] - 1].countryid),
            toCountryName: "".concat(this.sellerCity[e[1] - 1].countryname),
            fromCountryId: "".concat(this.sellerCity[e[1] - 1].countryid),
            fromCountryName: "".concat(this.sellerCity[e[1] - 1].countryname),
            name: "".concat(this.sellerCity[e[1] - 1].countryname, "-").concat(this.sellerCity[e[1] - 1].countryname)
          }, {
            id: 3,
            toCountryId: 2,
            toCountryName: "中国",
            fromCountryId: 1,
            fromCountryName: "United States",
            name: "\u4E2D\u56FD-United States"
          }];
        } else {
          this.buyerCity = [];
        }
      }
      this.buyrules = [];
      this.createForm.setFieldsValue({
        sellcountry: []
      });
    },
    // 买家选择
    buyerhandleChange: function buyerhandleChange(e) {
      var _this5 = this;
      if (e.length > this.buyrules.length) {
        e.map(function (v) {
          _this5.buyerCity.map(function (vs) {
            if (v == vs.name) {
              _this5.buyrules.push({
                // id: vs.id,
                router: vs.name,
                toCountryId: vs.toCountryId,
                toCountryName: vs.toCountryName,
                fromCountryId: vs.fromCountryId,
                fromCountryName: vs.fromCountryName,
                defaultPrice: "",
                expressCompanyCode: "",
                chargingList: [{
                  // chargingId: "",
                  shipping: "",
                  receiving: "",
                  isWarehouse: false,
                  price: "",
                  type: 2
                }]
              });
              var obj = {};
              _this5.buyrules = _this5.buyrules.reduce(function (cur, next) {
                obj[next.router] ? "" : obj[next.router] = true && cur.push(next);
                return cur;
              }, []);
            }
          });
        });
      }
      this.buyrules = this.buyrules.filter(function (item) {
        return e.includes(item.router);
      });
      // console.log(e, this.buyrules);
    },
    // 提交
    handleAdd: function handleAdd(e) {
      var _this6 = this;
      if (e) {
        this.$router.push("/logistics/index");
      } else {
        this.createForm.validateFields(function (err, value) {
          // console.log(this.rules);
          var showVisible = false;
          _this6.rules.map(function (v) {
            v.chargingList.map(function (vs, i) {
              var price = vs.price,
                receiving = vs.receiving,
                shipping = vs.shipping;
              if (!price && !receiving && !shipping) {
                v.chargingList.splice(i, 1);
              } else {
                for (var key in vs) {
                  if (vs[key] != "0" && !vs[key]) {
                    _this6.$message.warning("请完善卖家表格配置");
                    showVisible = true;
                    return false;
                  }
                }
              }
            });
          });
          _this6.buyrules.map(function (v) {
            v.chargingList.map(function (vs, i) {
              var price = vs.price,
                receiving = vs.receiving,
                shipping = vs.shipping;
              if (!price && !receiving && !shipping) {
                v.chargingList.splice(i, 1);
              } else {
                for (var key in vs) {
                  if (vs[key] != "0" && !vs[key]) {
                    _this6.$message.warning("请完善买家表格配置");
                    showVisible = true;
                    return false;
                  }
                }
              }
            });
          });
          if (showVisible) return;
          var templateName = value.templateName;
          var params = {
            templateName: templateName,
            rules: _this6.rules,
            routes: _this6.buyrules
          };
          if (!err) {
            if (!_this6.editId) {
              LogisticApi.savelogistic(params).then(function (res) {
                if (res) {
                  _this6.$router.push("/logistics/index?addType=1");
                }
              });
            } else {
              LogisticApi.updatelogistic(_objectSpread(_objectSpread({}, params), {}, {
                templateId: _this6.editId
              })).then(function (res) {
                if (res) {
                  _this6.$router.push("/logistics/index");
                }
              });
            }
          } else {
            console.log(err);
          }
        });
      }
    },
    filterOption: function filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // 获取国家
    getCountry: function getCountry() {
      var _this7 = this;
      LogisticApi.getNewCountry({
        scene: 1
      }).then(function (res) {
        // console.log(res);
        _this7.sellerCity = res;
      });
    } //
  })
};