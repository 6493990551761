import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.to-fixed.js";
import Addressadd from "../addressadd";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    buyrules: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    expresscompanyList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    seetype: {
      type: Boolean,
      default: false
    },
    buyerWareList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    Addressadd: Addressadd
  },
  data: function data() {
    return {
      columns: [{
        title: "发货地(仓库)",
        dataIndex: "shipping",
        key: "shipping",
        width: "30%",
        align: "center",
        scopedSlots: {
          customRender: "shipping"
        }
      }, {
        title: "收货地(州)",
        dataIndex: "receiving",
        key: "receiving",
        width: "30%",
        align: "center",
        scopedSlots: {
          customRender: "receiving"
        }
      }, {
        title: "运费计算($)",
        dataIndex: "price",
        key: "price",
        width: "20%",
        align: "center",
        scopedSlots: {
          customRender: "price"
        }
      }, {
        title: "操作",
        dataIndex: "more",
        key: "more",
        width: "20%",
        align: "center",
        scopedSlots: {
          customRender: "more"
        }
      }],
      addressVisible: false,
      addressIndex: "",
      title: "添加地址",
      editaddress: "",
      rulesIndex: "",
      countrychooseId: ""
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState("base", ["warehouseList"])), {}, {
    defaultWareList: function defaultWareList() {
      var _this = this;
      return this.warehouseList.filter(function (item) {
        return _this.buyerWareList.includes(item.id);
      });
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapActions("base", ["getWarehouseList"])), {}, {
    // 选择发货地
    showAddress: function showAddress(e, vl, index) {
      // console.log(e, vl, record);
      this.addressIndex = e;
      if (this.buyrules[index].chargingList[this.addressIndex].shipping == "" || !this.buyrules[index].chargingList[this.addressIndex].shipping) {
        this.$message.warning("请先选择发货地;同一个发货地不能有相同的收货地");
        return;
      }
      this.addressVisible = true;
      this.rulesIndex = index;
      if (vl && vl !== "") {
        // console.log(111);
        this.editaddress = vl.split(",");
        this.title = "编辑地址";
      } else {
        this.title = "新增地址";
        this.editaddress = "";
      }
      this.countrychooseId = this.buyrules[index].fromCountryId;
    },
    //    添加一行
    handleAddRow: function handleAddRow(index) {
      this.buyrules[index].chargingList.push({
        shipping: "",
        receiving: "",
        isWarehouse: false,
        price: "",
        type: 2
      });
      // console.log(this.buyrules[index]);
    },
    // 删除一行
    deleteClick: function deleteClick(index, i) {
      this.buyrules[index].chargingList.splice(i, 1);
    },
    handleCancle: function handleCancle() {
      this.addressVisible = false;
    },
    handleAddSubmitSuccess: function handleAddSubmitSuccess(e) {
      var _this2 = this;
      this.addressVisible = false;
      // console.log(e, "address");
      // console.log(this.buyrules[this.rulesIndex].chargingList[this.addressIndex].shipping);
      var showDiseable = false;
      // let names = this.buyrules[this.rulesIndex].chargingList.map(
      //     (item) => item["shipping"]
      // );
      // console.log(names.includes(this.buyrules[this.rulesIndex].chargingList[this.addressIndex].shipping));
      // let nameSet = new Set(names);
      // if (nameSet.size !== names.length) {
      //     // console.log("有重复值");
      //     this.buyrules[this.rulesIndex].chargingList.map((v, i) => {
      //         console.log(v, v.shipping);
      //         if (this.addressIndex === i) {
      //             console.log(v);
      //             v.receiving = "";
      //         // if(this.buyrules[this.rulesIndex].chargingList[i])

      //         }
      //         e.map((vs, is) => {
      //             // console.log(v, vs);
      //             if (v.receiving.includes(vs)) {
      //                 showDiseable = true;
      //                 return;
      //             }
      //         });
      //     });
      // }
      this.buyrules[this.rulesIndex].chargingList.map(function (v, i) {
        var ClickShip = _this2.buyrules[_this2.rulesIndex].chargingList[_this2.addressIndex].shipping;
        if (ClickShip == _this2.buyrules[_this2.rulesIndex].chargingList[i].shipping) {
          if (_this2.addressIndex === i) {
            v.receiving = "";
          }
          var receArrs = _this2.buyrules[_this2.rulesIndex].chargingList[i].receiving.split(",");
          receArrs.map(function (rs) {
            if (rs.indexOf("全部") != -1) {
              e.map(function (vs) {
                if (rs.split("-")[0] == vs.split("-")[0]) {
                  showDiseable = true;
                  return;
                }
              });
            }
          });
          e.map(function (vs) {
            if (vs.indexOf("全部") != -1) {
              receArrs.map(function (rs) {
                if (vs.split("-")[0] == rs.split("-")[0]) {
                  showDiseable = true;
                  return;
                }
              });
            }
            if (_this2.buyrules[_this2.rulesIndex].chargingList[i].receiving.includes(vs)) {
              showDiseable = true;
              return;
            }
          });
        }
      });
      if (showDiseable) {
        this.$message.warning("地址已存在,不可重复添加");
        return;
      }
      this.buyrules[this.rulesIndex].chargingList[this.addressIndex].receiving = e.join(",");
      this.addressVisible = false;
    },
    // 获取默认运费
    PriceChange: function PriceChange(va, idx) {
      var _this3 = this;
      console.log(va, idx);
      this.$nextTick(function () {
        // this.$set(this.buyrules[idx], defaultPrice, va)
        _this3.buyrules[idx].defaultPrice = va;
      });
    },
    // 获取物流公司
    CompanyCodeChange: function CompanyCodeChange(va, idx) {
      this.buyrules[idx].expressCompanyCode = va;
    },
    // 获取表格运费
    charPriceChange: function charPriceChange(va, index, i) {
      var reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
      // console.log(reg.test(va));
      if (!reg.test(va)) {
        this.$message.error("运费请保留两位小数");
        this.buyrules[index].chargingList[i].price = va.toFixed(2);
      }

      // console.log(this.buyrules[index].chargingList[i].price);
    }
  }),
  mounted: function mounted() {
    if (!this.warehouseList) {
      this.getWarehouseList();
    }
  }
};