import { render, staticRenderFns } from "./addressadd.vue?vue&type=template&id=6aa03280&scoped=true&"
import script from "./addressadd.vue?vue&type=script&lang=js&"
export * from "./addressadd.vue?vue&type=script&lang=js&"
import style0 from "./addressadd.vue?vue&type=style&index=0&id=6aa03280&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa03280",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\projects\\operation-management-background\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6aa03280')) {
      api.createRecord('6aa03280', component.options)
    } else {
      api.reload('6aa03280', component.options)
    }
    module.hot.accept("./addressadd.vue?vue&type=template&id=6aa03280&scoped=true&", function () {
      api.rerender('6aa03280', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/logisticsManagement/addressadd.vue"
export default component.exports