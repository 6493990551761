import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.to-fixed.js";
import Addressadd from "../addressadd";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  props: {
    rules: {
      type: Array,
      default: function _default() {
        return "";
      }
    },
    expresscompanyList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    seetype: {
      type: Boolean,
      default: false
    },
    sellerCity: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    Addressadd: Addressadd
  },
  data: function data() {
    return {
      columns: [{
        title: "发货地",
        dataIndex: "shipping",
        key: "shipping",
        width: "40%",
        align: "center",
        scopedSlots: {
          customRender: "shipping"
        }
      }, {
        title: "收货地(仓库)",
        dataIndex: "receiving",
        key: "receiving",
        width: "20%",
        align: "center",
        scopedSlots: {
          customRender: "receiving"
        }
      }, {
        title: "运费计算($)",
        dataIndex: "price",
        key: "price",
        width: "20%",
        align: "center",
        scopedSlots: {
          customRender: "price"
        }
      }, {
        title: "操作",
        dataIndex: "more",
        key: "more",
        width: "20%",
        align: "center",
        scopedSlots: {
          customRender: "more"
        }
      }],
      addressVisible: false,
      addressIndex: "",
      title: "添加地址",
      editaddress: "",
      rulesIndex: "",
      countrychooseId: ""
      // newArr: [],
    };
  },

  computed: _objectSpread({}, mapState("base", ["warehouseList"])),
  watch: {
    // rules: {
    //     handler(newVal, oldVal) {
    //         newVal.map((v, i) => {
    //             this.newArr = this.rules[i].chargingList.filter((it) => {
    //                 return !it.isDeleted;
    //             });
    //         });
    //         // console.log("this.newArr-----------", this.newArr);
    //     },
    //     immediate: true,
    //     deep: true,
    // },
  },
  methods: _objectSpread(_objectSpread({}, mapActions("base", ["getWarehouseList"])), {}, {
    // 选择发货地
    showAddress: function showAddress(e, vl, index) {
      // console.log(e, vl);
      this.addressIndex = e;
      this.addressVisible = true;
      this.rulesIndex = index;
      if (vl && vl !== "") {
        // console.log(111);
        this.editaddress = vl.split(",");
        this.title = "编辑地址";
      } else {
        this.title = "新增地址";
        this.editaddress = "";
      }
      this.countrychooseId = this.rules[index].countryId;
      // console.log(this.countrychooseId);
    },
    //    添加一行
    handleAddRow: function handleAddRow(index) {
      this.rules[index].chargingList.push({
        shipping: "",
        receiving: "",
        isWarehouse: true,
        price: "",
        type: 1
      });
    },
    // 删除一行
    deleteClick: function deleteClick(index, i, record) {
      // console.log(record);
      this.rules[index].chargingList.splice(i, 1);
      // this.rules[index].chargingList[i].isDeleted = true;
      // this.rules[index].chargingList.map((el, idx) => {
      //     if (!el.chargingId) {
      //         this.rules[index].chargingList.splice(i, 1);
      //     }
      //     if (idx == i) {
      //         el.isDeleted = true;
      //     }
      // });
      // this.newArr.map((nl, inx) => {
      //     if (nl.chargingId == record.chargingId) {
      //         this.newArr.splice(inx, 1);
      //     }
      // });
    },
    handleCancle: function handleCancle() {
      this.addressVisible = false;
    },
    handleAddSubmitSuccess: function handleAddSubmitSuccess(e) {
      var _this = this;
      this.addressVisible = false;
      // console.log(e, "address");
      var showDiseable = false;
      this.rules[this.rulesIndex].chargingList.map(function (v, i) {
        e.map(function (vs, is) {
          if (_this.addressIndex === i) {
            v.shipping = "";
          }
          if (vs.indexOf("全部") != -1) {
            // if (v.shipping.indexOf("全部") != -1) {
            // console.log(v, vs);
            if (v.shipping.split("-")[0] == vs.split("-")[0]) {
              showDiseable = true;
              return;
            }
          } else {
            // console.log(v, i, vs, this.rules[this.rulesIndex].chargingList[i],is)
            // console.log(v, e);
            // console.log(v.shipping.includes(vs));
            // console.log(e.includes(vs));
            // if (this.addressIndex === i) {
            //     v.shipping = "";
            // }
            if (v.shipping.indexOf("全部") != -1) {
              // console.log(v, vs);
              if (v.shipping.split("-")[0] == vs.split("-")[0]) {
                showDiseable = true;
                return;
              }
            }
            if (v.shipping.includes(vs)) {
              showDiseable = true;
              return false;
            }
          }
        });
      });
      if (showDiseable) {
        this.$message.warning("地址已存在,不可重复添加");
        return;
      }
      this.rules[this.rulesIndex].chargingList[this.addressIndex].shipping = e.join(",");
      this.addressVisible = false;
    },
    // 获取仓库名称
    handleChange: function handleChange(va, key, index) {
      this.rules[index].warehouseId = key;
      this.rules[index].warehouseName = va;
    },
    // 获取默认运费
    defaultPriceChange: function defaultPriceChange(va, idx) {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.rules[idx].defaultPrice = va;
      });
    },
    // 获取物流公司
    CompanyCodeChange: function CompanyCodeChange(va, idx) {
      this.rules[idx].expressCompanyCode = va;
    },
    // 获取表格运费
    charPriceChange: function charPriceChange(va, index, i) {
      var reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/;
      if (!reg.test(va)) {
        this.$message.error("运费请保留两位小数");
        this.rules[index].chargingList[i].price = va.toFixed(2);
      }
    }
  }),
  mounted: function mounted() {
    // console.log();
    if (!this.warehouseList) {
      this.getWarehouseList();
    }
  }
};